// overrides of nav-related bits in minima/_layout.scss

.site-nav {
  @include media-query($on-palm) {
    position: absolute;
    border: 0;

    label[for="nav-trigger"] {
      position: relative;
    }

    .trigger {
      position: relative;
      z-index: 999;

      .page-link {
        background: $background-color;
      }
    }
  }
}

.site-header {
  border: 0;
}

.site-footer {
  padding: 5rem 0 2rem 0;
  border: 0;

  @include media-query($on-palm) {
    text-align: right;
  }
}