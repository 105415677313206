body {
  p {
    padding-bottom: .3rem;
  }
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.column {
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  padding: 0 1rem;
}

.post-header {
  padding-left: 1rem;
}

.left-rail {
  max-width: 20rem;
  min-width: 15rem;
  padding-bottom: 2rem;
  width: 100%;
}
