@charset "utf-8";

@import "fonts";
$header-font-family: Playfair !default;
$base-font-family: Dosis !default;
$base-font-size:   1.1rem !default;
$base-font-weight: 400 !default;
$small-font-size:  $base-font-size * 0.875 !default;
$base-line-height: 1.5 !default;

$spacing-unit:     2rem !default;

$text-color:       #111 !default;
$background-color: #eee7e4 !default;
$brand-color:      #844845 !default;

$grey-color:       #484848 !default;
$grey-color-light: lighten($grey-color, 40%) !default;
$grey-color-dark:  darken($grey-color, 25%) !default;

$table-text-align: left !default;

// Import partials.
@import
  "minima/base",
  "minima/layout",
  "minima/syntax-highlighting"
;

// Import custom CSS partials
@import "custom";
