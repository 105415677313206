
.article-box {
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
}

.article {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.article-image {
  opacity: 0.3;
  position: absolute;
  left: 0;
  top: -50%;
  width: 100%;
  height: -webkit-fill-available;
  object-fit: cover;
}

.article-title {
  font-family: $header-font-family;
  font-size: larger;
}

.article-text {
  position: relative;
  margin: .75rem 0 .75rem 0;
  display: flex;
  align-content: flex-end;
  justify-content: space-evenly;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-end;
}

.article-highlight {
  display: inline-block;
  padding: 0 1rem;
  background: white;
}
