// minima theme overrides
@import
  "nav"
;

// content specific
@import
  "ahs/main", 
  "ahs/form", 
  "ahs/articles", 
  "ahs/obit",
  "ahs/poems"
;

// other css
@import 
  "fades"
;

.pad-top {
  padding-top: 2rem;
}

body {
  font: $base-font-weight #{$base-font-size}/#{$base-line-height} $base-font-family;
}

.push-right {
  display: flex;
  justify-content: flex-end;
}

h1, h2, h3, h4, h5, h6,
header {
  font-family: $header-font-family;
}

h1 {
  font-size: xxx-large;

  @include media-query($on-palm) {
    font-size: xx-large;
  }
}

// override: get rid of borders
.site-header {
  border: 0;
}
.site-footer {
  border: 0;

  @include media-query($on-palm) {
    text-align: right;
  }
}
