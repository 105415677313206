
@font-face {
  font-family: "Playfair";
  src: url("../assets/fonts/playfair-display-medium.ttf");
  font-display: swap;
}

@font-face {
  font-family: "PlayfairItalic";
  src: url("../assets/fonts/playfair-display-mediumitalic.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Dosis";
  src: url("../assets/fonts/dosis.ttf");
  font-display: swap;
}


// Width of the content area
$content-width:    70rem !default;

$on-palm:          30rem !default;
$on-laptop:        70rem !default;

// Use media queries like this:
// @include media-query($on-palm) {
//   .wrapper {
//     padding-right: $spacing-unit / 2;
//     padding-left: $spacing-unit / 2;
//   }
// }
@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

@mixin relative-font-size($ratio) {
  font-size: $base-font-size * $ratio;
}

