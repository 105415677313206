// via https://stackoverflow.com/questions/11679567/using-css-for-a-fade-in-effect-on-page-load

$fade-time: 0.8s;

div {
    -webkit-animation: fadein $fade-time; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein $fade-time; /* Firefox < 16 */
        -ms-animation: fadein $fade-time; /* Internet Explorer */
         -o-animation: fadein $fade-time; /* Opera < 12.1 */
            animation: fadein $fade-time;
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}